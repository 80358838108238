import { AppConfig, Environment } from 'shared-domain';

import { environment } from '../environments/environment';
import { countries } from './countries';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'Hashflow',
  languages: ['en'],
  countries,
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
};
